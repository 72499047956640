/* tslint:disable */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AppMainComponent } from './app.main.component';
import { AppLoginComponent } from './login/app.login.component';
import { DashboardComponent } from './dashboard/dashboard.component'
import { ImportDataComponent } from './importData/importData.component';
import { TabelaComponent } from './tabela/tabela.component';
import { BotComponent } from './bot/bot.component';
import { DashseinfraComponent } from './dashseinfra/dashseinfra.component';
import { DashsedurComponent } from './dashsedur/dashsedur.component';
import { UsuarioComponent } from './usuario/usuario.component';
/*
{ path: 'painelControl',  canActivate: [AuthGuard], canLoad: [AuthGuard],   data: { title: 'Call' }, loadChildren: () => import('./painelControl/painelControl.module').then(m => m.PainelControlModule)},
*/
export const routes: Routes = [
    { path: 'login', component: AppLoginComponent },
    { path: 'dashseinfra', canActivate: [AuthGuard], canLoad: [AuthGuard], component: DashseinfraComponent },
    { path: 'dashboard', canActivate: [AuthGuard], canLoad: [AuthGuard], component: DashboardComponent },
    { path: 'dashsedur', canActivate: [AuthGuard], canLoad: [AuthGuard], component: DashsedurComponent },
    {
        path: '', component: AppMainComponent, canActivate: [AuthGuard], data: { title: 'Main' },
        children: [
           // { path: 'dashboard', component: DashboardComponent },
            { path: 'importData', canActivate: [AuthGuard], canLoad: [AuthGuard], component: ImportDataComponent},
            { path: 'tabela', canActivate: [AuthGuard], canLoad: [AuthGuard], component: TabelaComponent },
            { path: 'bot', canActivate: [AuthGuard], canLoad: [AuthGuard], component: BotComponent },
            { path: 'usuario', canActivate: [AuthGuard], canLoad: [AuthGuard], component: UsuarioComponent},
            { path: 'grupo', canActivate: [AuthGuard], canLoad: [AuthGuard], data: { title: 'Grupo' }, loadChildren: () => import('./grupo/grupo.module').then(m => m.GrupoModule) },

        ]
    },
    { path: 'login', component: AppLoginComponent },
    { path: '**', redirectTo: '/notfound' }
]
@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
