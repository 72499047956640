import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GenProvider } from '../../providers/gen/gen';
import { AlertService } from '../services/alert.service';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs-compat/Subscription';
import { FormGroup } from '@angular/forms'
import { ValidaCampoProvider } from 'src/providers/valida-campo/valida-campo';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs-compat';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'

import { SafePipe } from 'src/pipes/safe/safe.pipe';

@Component({
    selector: 'app-dashseinfra',
    templateUrl: './dashseinfra.component.html',
    styleUrls: ['./dashseinfra.component.scss'],
    providers: [ConfirmationService, MessageService]
})
export class DashseinfraComponent implements OnInit, OnDestroy {
    @Input() btnClosed: boolean = false;

    unSub: Subscription[] = [];
    unMsg: Subscription;
    frame: any;
    showRelatorio: boolean;
    showDash: boolean;
    constructor(
        private genProvider: GenProvider,
        private router: Router,
        private safePipe: SafePipe
    ) {
        this.frame = "https://hyperchannel.com.br"

    }

    onClose() {
        /*
          console.log()
          if (this.btnClosed){
            this.showDash = true
         //   this.router.navigate(['/']);
         //   this.router.navigate(['dashboard']);
          }else {
            this.router.navigate(['dashboard']);
          //  this.router.navigate(['']);
          }
          */
        this.router.navigate(['']);
    }
    ngOnDestroy() {
        console.log("destroying child...")
        this.unSub.forEach(s => {
            s.unsubscribe();
        });
        if (this.unMsg != undefined) {
            this.unMsg.unsubscribe();
        }
        this.router.navigate(['dashboard']);
    }
    onCombos() {
    }
    ngOnInit() {
        console.log(this.router)

    }
    onShowBi(value) {
        if (value == 'ata') {
            this.frame = "https://app.powerbi.com/view?r=eyJrIjoiOGFhM2E2NjItYTVlZi00MDVlLWFjOTUtYTA1OTBiMWYyZGY4IiwidCI6ImVlMjY3ZTQxLWM4NjYtNDE1Yi1iODBiLTQzNDE0OTRhMjE5NCJ9&pageName=ReportSection"
            this.showRelatorio = true;
        } else if (value == 'licitacao') {
            this.frame = "https://app.powerbi.com/view?r=eyJrIjoiZWYyZWU0MjgtZTM2OC00ODlhLTkxODAtMzdkM2QzMWNhMjllIiwidCI6ImVlMjY3ZTQxLWM4NjYtNDE1Yi1iODBiLTQzNDE0OTRhMjE5NCJ9&pageName=ReportSection"
            this.showRelatorio = true;
        } else if (value == 'contrato') {
            this.frame = "https://app.powerbi.com/view?r=eyJrIjoiYjUwM2E2OGEtZmFmNS00MDBkLWJlZjEtMjM5NjA0NGFmNzJmIiwidCI6ImVlMjY3ZTQxLWM4NjYtNDE1Yi1iODBiLTQzNDE0OTRhMjE5NCJ9"
            this.showRelatorio = true;
        } else if (value == 'obra') {
            this.frame = "https://app.powerbi.com/view?r=eyJrIjoiMzBlNWM5NTMtZmM1OC00ZmZjLThmZDAtZjE4ZDI4ZmQyOTJlIiwidCI6ImVlMjY3ZTQxLWM4NjYtNDE1Yi1iODBiLTQzNDE0OTRhMjE5NCJ9"
            this.showRelatorio = true;
        } else if (value == 'mapa') {
            this.frame = "https://app.powerbi.com/view?r=eyJrIjoiZWUwNmNmZDItNDk3Yi00N2JiLWE0MWQtZmRlMTEzZjBiZGMxIiwidCI6ImVlMjY3ZTQxLWM4NjYtNDE1Yi1iODBiLTQzNDE0OTRhMjE5NCJ9&pageName=ReportSectione865d3dca8c4071f12c0"
            this.showRelatorio = true;
        }
        else if (value == 'rh') {
            this.frame = "https://app.powerbi.com/view?r=eyJrIjoiNzU5NjIwMmYtOTFlYy00N2E1LTllNTMtNDM0NGI1OGFiMjg4IiwidCI6ImVlMjY3ZTQxLWM4NjYtNDE1Yi1iODBiLTQzNDE0OTRhMjE5NCJ9"
            this.showRelatorio = true;
        }
    }
    url() {
        return this.safePipe.transform(this.frame, 'resourceUrl');
    }
}
