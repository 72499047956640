export const navigation = [
    {nome: 'Governador', icon: 'pi pi-fw pi-chart-bar', routerLink: ['dashboard']},
    {nome: 'Seinfra', icon: 'pi pi-fw pi-chart-bar', routerLink: ['dashseinfra']},
    {nome: 'Sedur', icon: 'pi pi-fw pi-chart-bar', routerLink: ['dashsedur']},
    {nome: 'Importar dados', icon: 'pi pi-upload', routerLink: ['importData']},
    {nome: 'Tabelas', icon: 'pi pi-upload', routerLink: ['tabela']},
    {
        nome: 'Configurações', icon: 'pi pi-fw pi-cog', routerLink: ['/pages'],
        itens: [
            {nome: 'Grupos', icon: 'fa fa-users', routerLink: ['grupo']},
            {nome: 'Usuários', icon: 'fa fa-user', routerLink: ['usuario']},
        ]
    }
];
