/* tslint:disable */

import { Router } from '@angular/router';
import { Injectable, EventEmitter } from '@angular/core';
import { GenProvider } from 'src/providers/gen/gen';
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { take } from 'rxjs/operators';
import { AlertService } from '../services/alert.service';

@Injectable()
export class AuthService  {

  login: any;
  private usuarioAutenticado: boolean = false;

  mostrarMenuEmitter = new EventEmitter<boolean>();

  constructor(
    private genProvider: GenProvider,
    private router: Router,
    public http: HttpClient,
    private alertServie: AlertService) {

  }

  fazerLogin(usuario) {
    // this.validaIp()

    this.router.navigate(['/']);
    this.genProvider.onGetfilter('login', 'login', usuario.nome.toLowerCase()).pipe(take(1)).subscribe(data => {
      this.login = JSON.parse(JSON.stringify(data));
       if (this.login.length > 0) {
        if (this.login[0].senha == usuario.senha) {
          // atualiza dados de configuracao do sistem
          this.genProvider.onParametros(this.login[0])
          this.usuarioAutenticado = true;
          this.mostrarMenuEmitter.emit(true);
          this.genProvider.onGetKey('grupo', this.login[0].key_grupo).pipe(take(1)).subscribe(myg => {
            let grupo = JSON.parse(JSON.stringify(myg));
            if (grupo.change) {
              this.router.navigate(['/' + grupo.change]);
            } else {
              this.router.navigate(['/']);
            }
          })
          //  this.router.navigate(['/dashboard']);
          //  this.router.navigate(['/home']);
          //  this.router.navigate(['/ocorrencia']);
        }
        else {
          this.alertServie.error("Senha incorreta, tente novamente!")
          this.usuarioAutenticado = false;
          this.mostrarMenuEmitter.emit(false);
        }
      } else {
        this.alertServie.error('Nome de Usuário incorreto, tente novamente!');
        this.usuarioAutenticado = false;
        this.mostrarMenuEmitter.emit(false);
      }
    },
      err => {
        console.log(err)
        this.alertServie.error('Erro ao acessar o servidor, tente novamente!');
        this.usuarioAutenticado = false;
        this.mostrarMenuEmitter.emit(false);

      }
    )

  }
  usuarioEstaAutenticado() {
    return this.usuarioAutenticado;
  }
  validaIp() {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json",
      "method": "post"
    });
    let options = {
      headers: headers
    }
    console.log("aqui")

    let url = "http://meuip.com/api/meuip.php"
    this.http.get(url).subscribe(res => {
      console.log(res);
    });

    let a = this.http.post(url, options).subscribe(res => {
      return res;
    });

    console.log(a)

    this.http.get(url, options).subscribe(
      dados => {
        console.log(dados)
        // return dados.toString();
      }
    );

  }

}
