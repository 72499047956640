// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment_ant = {
  production: true,
  FirebaseConfig : {
    apiKey: "AIzaSyCLCRasCCRzc2O2Gow4XweC2Cp3npitDec",
    authDomain: "omni-acre.firebaseapp.com",
    projectId: "omni-acre",
    storageBucket: "omni-acre.appspot.com",
    messagingSenderId: "294032374855",
    appId: "1:294032374855:web:edb63de89c0c118cff4562",
    measurementId: "G-GSSQYF6ZHM"
  }
}
export const environment = {
  production: true,
  FirebaseConfig : {
    databaseURL: "https://mni-acre.firebaseio.com",
    apiKey: "AIzaSyCLCRasCCRzc2O2Gow4XweC2Cp3npitDec",
    authDomain: "omni-acre.firebaseapp.com",
    projectId: "omni-acre",
    storageBucket: "omni-acre.appspot.com",
    messagingSenderId: "294032374855",
    appId: "1:294032374855:web:edb63de89c0c118cff4562",
  }
}
