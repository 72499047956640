import { Component } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { GenProvider } from 'src/providers/gen/gen';
import { navigation } from './_nav';
import { OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app.sidebar.component.html'
})
export class AppSideBarComponent implements OnInit {
  public navigation: any;
  public model: any[];
  permissao: any;
  constructor(public app: AppMainComponent, public genProvider: GenProvider) {
    this.navigation = navigation;
  }
  ngOnInit() {
    setTimeout(() => {
      this.isMenu();
      this.app.sidebarActive = true
      this.app.activeTabIndex = 0; 
    }, 1000);
  }

  public isDivider(item) {
    return item.divider ? true : false
  }
  public isTitle(item) {
    return item.title ? true : false
  }
  public isShow(item) {
    return item.show ? true : false
  }
  public isMenu() {
    let parametro = this.genProvider.parametros;
    console.log (parametro)
    if (parametro.nivel == "99") {
        for (let index = 0; index < this.navigation.length; index++) {
          this.navigation[index].show = true
          console.log ("aqui")
          /*
          if (!this.isDivider(this.navigation[index]) && !this.isTitle(this.navigation[index])) {
             if (parametro.nivel == "99") {
              this.navigation[index].show = true
              let child: any
              try {
                child = this.navigation[index].itens || false
              } catch (error) {
                child = false;
                console.log(error)
              }
              if (child) {
                for (let i = 0; i < this.navigation[index].itens.length; i++) {
                  if (parametro.nivel == "99") {
                    this.navigation[index].itens[i].show = true
                  } else {
                    this.navigation[index].itens[i].show = false
                  }
                }
              }
            } else {
              this.navigation[index].show = false
            }
          }
          */
        }
        this.model = this.navigation;
    
    }else {
      try {
        this.genProvider.onGetFilterOrder('login_permissao', 'nome', 'key_login', parametro.key_login)
          .subscribe(data => {
            this.model = [];
            console.log(data, parametro.key_login)
            this.permissao = JSON.parse(JSON.stringify(data))
            for (let index = 0; index < this.navigation.length; index++) {
              if (!this.isDivider(this.navigation[index]) && !this.isTitle(this.navigation[index])) {
                let res = this.permissao.filter(obj => {
                  return obj.nome == this.navigation[index].nome
                });
                if (res.length > 0) {
                  console.log("1")
                  this.navigation[index].show = !res[0].per_bloqueio
                  let child: any
                  try {
                    child = this.navigation[index].itens || false
                  } catch (error) {
                    child = false;
                    console.log(error)
                  }
                  if (child) {
                    for (let i = 0; i < this.navigation[index].itens.length; i++) {
                      let res = this.permissao.filter(obj => {
                        return obj.nome == this.navigation[index].itens[i].nome
                      });
                      if (res.length > 0) {
                        this.navigation[index].itens[i].show = !res[0].per_bloqueio
                      } else {
                        if (parametro.nivel == "99") {
                          this.navigation[index].itens[i].show = true
                        } else {
                          this.navigation[index].itens[i].show = false
                        }
                      }
                    }
                  }
  
                } else {
                  console.log("2")
                  if (parametro.nivel == "99") {
                    this.navigation[index].show = true
                    let child: any
                    try {
                      child = this.navigation[index].itens || false
                    } catch (error) {
                      child = false;
                      console.log(error)
                    }
                    if (child) {
                      for (let i = 0; i < this.navigation[index].itens.length; i++) {
                        if (parametro.nivel == "99") {
                          this.navigation[index].itens[i].show = true
                        } else {
                          this.navigation[index].itens[i].show = false
                        }
                      }
                    }
                  } else {
                    this.navigation[index].show = false
                  }
                }
              }
            }
            this.model = this.navigation;
          },
            err => {
              console.log(err)
            }
          );
      } catch (error) {
        if (parametro.nivel == "99") {
          for (let index = 0; index < this.navigation.length; index++) {
            if (!this.isDivider(this.navigation[index]) && !this.isTitle(this.navigation[index])) {
               if (parametro.nivel == "99") {
                this.navigation[index].show = true
                let child: any
                try {
                  child = this.navigation[index].itens || false
                } catch (error) {
                  child = false;
                  console.log(error)
                }
                if (child) {
                  for (let i = 0; i < this.navigation[index].itens.length; i++) {
                    if (parametro.nivel == "99") {
                      this.navigation[index].itens[i].show = true
                    } else {
                      this.navigation[index].itens[i].show = false
                    }
                  }
                }
              } else {
                this.navigation[index].show = false
              }
            }
          }
          this.model = this.navigation;
        }
      }
  
    }

  }
}
