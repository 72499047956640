import { NgModule } from '@angular/core';
import { CamelcasePipe } from './camelcase/camelcase';
import { FiltroarrayPipe } from './filtroarray/filtroarray';
import { SafePipe } from './safe/safe.pipe';

@NgModule({
	declarations: [CamelcasePipe,
    FiltroarrayPipe, SafePipe],
	imports: [],
	exports: [CamelcasePipe,
    FiltroarrayPipe, SafePipe]
})
export class PipesModule {}
