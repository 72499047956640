/* tslint:disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import 'rxjs/add/operator/map';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from "@angular/fire/storage";
import { GenProvider } from 'src/providers/gen/gen';
import { Table } from 'primeng/table';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { Http, Response } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';


@Component({
  selector: 'app-tabela',
  templateUrl: './tabela.component.html',
  styleUrls: ['./tabela.component.scss'],
  providers: [ConfirmationService]
})
export class TabelaComponent implements OnInit {
  @ViewChild('dtLista') dtLista: Table;
  @ViewChild('dtListaDados') dtListaDados: Table;
  employees: Observable<[]>;
  mostraFrm: boolean = false;
  path: any;
  formulario: FormGroup;
  parametro: any;
  lista: any;
  titulo: string;
  cols: any = [];
  exportColumns: any = [];
  status: any = [];
  formulario_sub: FormGroup;
  unSub: Subscription;
  permissao: any = true;
  loading: any;
  filtros: any;
  listaDados: any;
  exportColumnsDados: any;
  colsDados: { header: string; field: string; }[];
  header: any = [];
  mostraDados: boolean;
  tabela: any;
  anexarArquivo: boolean;
  fileSrc: any;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  progress: Observable<number>;
  snapshot: Observable<unknown>;
  downloadURL: Subscription;
  button: { cor: string; class: string; }[];
  options: RequestOptions;

  constructor(
    private formBuilder: FormBuilder,
    private genProvider: GenProvider,
    private router: Router,
    public storage: AngularFireStorage,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private afStorage: AngularFireStorage,
    public http: Http,

  ) {
    /*
    this.composeOptions ={
      to:['reginaldo.esteves@hotmail.com','paulospcoelho@gmail.com'],
      subject : 'My native email Hyper Chennel',
      body :'Este é um email enviado pela plataforma hyper channel',
      cc:['paulospcoelho@hotmail.com']
    }
    */
    this.path = this.genProvider.parametros.path;
    this.formulario = this.formBuilder.group({
      key: null,
      nome: [null, Validators.required],
      displayName: [null, Validators.required],
      descricao: [null, Validators.required],
      status: [null, Validators.required],
      fullName: '',
      midia: '',
      type: '',
      target: '',
      btnClass: ''
    });

  }
  onEmail() {
    let headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded  ;charset=iso-8859-1' });
    this.options = new RequestOptions({ headers: headers, method: "post" });
    let url = 'localhost:4200';
    let data = ''
    this.http.post(url, data, this.options).subscribe(
      res => {
        console.log(res)
      })


    /*
    email.available().then((avail: boolean) => {
      console.log("Email available? " + avail);
      if (avail){
        email.compose(this.composeOptions).then(res=>{
          console.log (res)
          console.log ('enviado')
        }).catch(error => console.error());
      }
  }).catch (error => console.error(error));
  */
  }

  onClose() {
    this.router.navigate(['']);
  }

  onMostraFrm() {
    this.mostraFrm = !this.mostraFrm;
  }
  onCombo() {
    this.status = [
      { nome: 'Ativo' },
      { nome: 'Desativado' }
    ]
    this.cols = [
      { header: 'Código', field: 'key' },
      { header: 'Display Name', field: 'displayName' },
      { header: 'Nome da tabela', field: 'nome' },
      { header: 'Descrição', field: 'descricao' },
      { header: 'Status', field: 'status' }
    ];
    this.button = [
      { cor: "Defalt", class: "p-button-primary" },
      { cor: "Cinza", class: "p-button-secondary" },
      { cor: "Verde", class: "p-button-success" },
      { cor: "Azul", class: "p-button-info" },
      { cor: "Amarelo", class: "p-button-warning" },
      { cor: "Purpura", class: "p-button-help" },
      { cor: "Vermelho", class: "p-button-danger" },
    ]
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
  }
  ngOnInit() {
    this.onCombo()
    this.onLoad();
  }
  ngOnDestroy() {
    console.log("destroying child...")
    //  this.unSub.forEach(s => {
    //    s.unsubscribe();
    //  });
  }
  onLoad() {
    this.loading = true;
    this.unSub = this.genProvider.onGetAll('dados/' + this.path + '/tabela', "nome").subscribe(data => {
      //    this.unSub.unsubscribe();
      this.lista = JSON.parse(JSON.stringify(data));
      this.loading = false;
    });
  }
  onNovo() {
    this.formulario.reset();
    this.titulo = 'Nova';
    this.onMostraFrm();
    this.permissao = false;
  }
  onEdit(event: any) {
    this.formulario.reset();
    this.formulario.patchValue(event);
    this.titulo = 'Editar';
    this.onMostraFrm();
    this.permissao = false;
  }

  onDeleteConfirm(event) {
    this.confirmationService.confirm({
      message: 'Confirma a Exclusão de ' + event.nome,
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptIcon: 'pi pi-check',
      rejectLabel: 'Não',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Exclusão confirmada' });
        this.genProvider.remove('dados/' + this.path + '/tabela', event.key);
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada' });
      }
    });

  }
  onSave() {
    if (this.formulario.valid) {
      this.onMostraFrm();
      if (this.anexarArquivo) {
        this.upLoadFile(this.formulario)
      } else {
        this.onSubmit();
      }
    }
  }
  onSubmit() {
    this.genProvider.onSaveIncrement("dados/" + this.path + "/tabela", this.formulario.value, "tabela").then(t => {
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
      this.formulario.reset();
    }).catch(e => {
      this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
      console.log(e);
    });
  }
  exportPdf() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    const doc = new jsPDF('p', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, "Tabelas")
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    //doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
      //      columnStyles: {
      //       0: { halign: 'left', fillColor: [220, 220, 220] }
      //     },
      //      margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumns,
      body: lista,
      // foot: this.listaFoot
    });
    doc.save('lista_tabelas.pdf');
  }
  onNome() {
    if (this.formulario.value.nome.length > 0) {
      this.formulario.patchValue({
        nome: this.formulario.value.nome.toLowerCase()
      })
    }
  }
  onDados(data) {
    if (data.fields) {
      this.header = data.fields
    }
    this.tabela = data.nome
    this.genProvider.onGetAll('dados/' + this.path + '/' + data.nome, 'time').pipe(take(1)).subscribe(res => {
      let data = JSON.parse(JSON.stringify(res));
      this.listaDados = data.map(res => {
        res.time = new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm");
        return res
      })
      if (this.listaDados.length > 0) {
        this.onComboDados()
      }
    })
  }
  onComboDados() {
    this.colsDados = this.header.map(col => ({ header: col.field.toUpperCase(), field: col.field }));
    this.filtros = this.header.map(col => (
      col.field
    ));
    this.exportColumnsDados = this.colsDados.map(col => ({ title: col.header, dataKey: col.field }));
    this.mostraDados = true;
  }
  exportPdfDados() {
    let lista: any;
    if (this.dtListaDados.filteredValue) {
      lista = this.dtListaDados.filteredValue
    } else {
      lista = this.listaDados
    }
    const doc = new jsPDF('l', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, this.tabela)
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    //doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
      //      columnStyles: {
      //       0: { halign: 'left', fillColor: [220, 220, 220] }
      //     },
      //      margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumnsDados,
      body: lista,
      // foot: this.listaFoot
    });
    doc.save('lista_dados_' + this.tabela + '.pdf');
  }
  onFile(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    this.formulario.patchValue({
      fullName: file.name,
      type: file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase(),
      target: e.target.files[0]
    });
    var reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
    this.anexarArquivo = true;
  }
  _handleReaderLoaded(e) {
    var reader = e.target;
    this.fileSrc = reader.result
    this.formulario.patchValue({
      midia: reader.result
    });
    console.log(this.formulario.value)
  }
  onUpload() {
    var botao = document.getElementById("FileUpload");
    botao.click();
  }
  upLoadFile(formulario: any) {
    let tabela = 'tabela'
    const filePath = `/${tabela}/file_${new DatePipe('pt-BR').transform(
      new Date(),
      'yyyy-MM-dd-HH:mm:ss'
    )}.` + formulario.value.fullName
    this.ref = this.afStorage.ref(filePath);
    this.task = this.ref.put(this.formulario.value.target);
    this.progress = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = this.ref.getDownloadURL().subscribe(res => {
          formulario.patchValue({
            midia: res,
            fullPath: filePath,
            target: ''
          })
          this.onSubmit();
          this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
        })
      })
    );
    this.snapshot.subscribe(
      res => {
        console.log(res);
      }, err => {
        console.log(err);
      }
    );
  }
}
