/* tslint:disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import 'rxjs/add/operator/map';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { MessageService } from 'primeng/api';
import { navigation } from '../_nav';
import { GenProvider } from 'src/providers/gen/gen';
import { AngularFireStorage } from '@angular/fire/storage';
import { CamelcasePipe } from 'src/pipes/camelcase/camelcase';
import { Table } from 'primeng/table';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {
  @ViewChild('dtLista') dtLista: Table;
  dados: any;
  filtro: string;
  mostraFrm: boolean = false;
  titulo: any;
  mostraMsg = false;
  textMsg = '';
  tituloMsg = '';
  path: any;
  formulario: FormGroup;
  parametro: any;
  cliente: any;
  nav: any;
  permissao = [];
  frmActive: any;
  acesso: any;
  grupo: any;
  setor: any;
  lotacao: any;
  status: { nome: string; }[];
  lista: any;
  isEdita: boolean;
  pessoa: any;
  unSub: Subscription[] = [];
  cols: { header: string; field: string; }[];
  exportColumns: { title: string; dataKey: string; }[];
  login: string;
  loading: any;
  constructor(
    private formBuilder: FormBuilder,
    private genProvider: GenProvider,
    private router: Router,
    public storage: AngularFireStorage,
    private messageService: MessageService
  ) {
    this.parametro = this.genProvider.parametros;
    this.grupo = [];
    this.formulario = this.formBuilder.group({
      key: '',
      key_login: null,
      key_grupo: [null, Validators.required],
      nome_grupo: '',
      nome: [null, Validators.required],
      login: [null, Validators.required],
      senha: [null, Validators.required],
      status: [null, Validators.required],
      key_pessoa: '',
      key_lotacao: '',
      data_hora: '',
      path: '',
      nivel: '',
      imagem: ''
    });
  }

  onClose() {
    this.router.navigate(['']);
  }

  onMostraFrm() {
    this.mostraFrm = !this.mostraFrm;
  }
  ngOnDestroy() {
    console.log("destroying child...")
    this.unSub.forEach(s => {
      s.unsubscribe();
    });
  }
  ngOnInit() {
    if (this.parametro.nivel == '99') {
      this.isEdita = true;
    } else {
      this.isEdita = false
    }
    this.status = [
      { nome: 'Ativo' },
      { nome: 'Desativado' }
    ];
    this.cols = [
      { header: 'Código', field: 'key' },
      { header: 'Nome', field: 'nome' },
      { header: 'Login', field: 'login' },
      // { header: 'Unidade Operacional', field: 'nome_lotacao' },
      { header: 'Grupo', field: 'nome_grupo' },
      { header: 'Empresa', field: 'path' },
      { header: 'Status', field: 'status' }
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.onLoad();
    this.onLoadGrupo();
    this.onLoadPessoa();
  }
  onLoad() {
    if (this.parametro.nivel == "99") {
      this.unSub.push(this.genProvider.onGetAll('login', 'nome').subscribe(data => {
        this.lista = JSON.parse(JSON.stringify(data));
        this.lista = this.lista.map(res => {
          res.busca = res.nome.toUpperCase();
          if (res.login) {
            res.busca += res.login.toUpperCase();
          }
          if (res.path) {
            res.busca += res.path.toUpperCase();
          }
          return res;
        });
        this.dados = this.lista;
      },
        err => console.log(err)
      ))
    } else {
      this.unSub.push(this.genProvider.onGetFilterOrder('login', 'nome', 'path', this.parametro.path).subscribe(data => {
        let lista = JSON.parse(JSON.stringify(data));
        this.lista = [];
        lista = lista.map(res => {
          res.busca = res.nome.toUpperCase();
          res.busca += res.login.toUpperCase();
          res.busca += res.path.toUpperCase();
          return res;
        });
        lista.forEach(e => {
          if (e.nivel != "99") {
            this.lista.push(e)
          }
        });
        this.dados = this.lista;
      },
        err => console.log(err)
      ));

    }
  }
  onLoadPessoa() {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.parametro.path + '/pessoa', 'nome').subscribe(data => {
      this.pessoa = JSON.parse(JSON.stringify(data));
    },
      err => console.log(err)
    ))
  }
  onPessoa(key) {
    let res = this.pessoa.filter(obj => {
      return obj.key == key
    });
    if (res.length > 0) {
      this.formulario.patchValue({
        nome: res[0].nome,
        key_lotacao: res[0].key_lotacao,
        nome_lotacao: res[0].nome_lotacao
      });
    }
  }
  onNovo() {
    this.novoUsuario()
  }
  novoUsuario() {
    let toDay = new DatePipe('pt-BR').transform(
      new Date(),
      'yyyy-MM-dd HH:mm:ss'
    );
    this.login = '';
    this.formulario.reset();
    this.formulario.patchValue({
      data_hora: toDay,
      key_login: Date.now().toString(),
      path: this.parametro.path
    });
    this.permissao = [];
    this.titulo = 'Novo';
    this.onMostraFrm();
  }
  onEdit(event: any) {
    this.permissao = [];
    this.formulario.reset();
    this.login = event.login;
    this.formulario.patchValue({
      key: event.key,
      key_grupo: event.key_grupo,
      key_login: event.key_login,
      key_lotacao: event.key_lotacao,
      key_pessoa: event.key_pessoa,
      nome: event.nome,
      login: event.login,
      senha: event.senha,
      status: event.status,
      grupo: event.grupo,
      data_hora: event.data_hora,
      nivel: event.nivel,
      path: event.path,
      imagem: event.imagem
    });
    this.onLoadPermissao(event.key_login);
    this.titulo = 'Editar';
    this.onMostraFrm();

  }
  onLoadPermissao(key) {
    let unsub = this.genProvider
      .onGetFilterOrder('login_permissao', 'ordem', 'key_login', key)
      .subscribe(data => {
        this.permissao = JSON.parse(JSON.stringify(data))
        unsub.unsubscribe();
      });
  }
  onDeleteConfirm(event) {
    this.onLoadPermissao(event.key)
    if (window.confirm('Confirma a Exclusão de ' + event.nome)) {
      this.genProvider.remove('login', event.key).then(res => {
        this.permissao.forEach(e => {
          this.genProvider.remove('login_permissao', e.key)
        })
      });
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Removidos.' });
    }
  }
  onSave() {
    this.formulario.value.nome = new CamelcasePipe().transform(this.formulario.value.nome).trim()
    this.formulario.value.login = this.formulario.value.login.toLowerCase();
    if (this.formulario.valid) {
      this.onLoadLogin(this.formulario.value.login).then(res => {
        let login = JSON.parse(JSON.stringify(res));
        if ((login.length > 0) && (!this.login)) {
          this.messageService.add({ severity: 'info', summary: 'Erro', detail: 'O login (' + this.formulario.value.login + ') já esta em uso na plataforma! Não foi possível gravar seus dados.' });
        } else if (login.length == 0) {
          this.onSubmit('login', this.formulario.value);
        } else if ((login.length > 0) && (this.login == this.formulario.value.login)) {
          this.onSubmit('login', this.formulario.value);
        } else if ((login.length > 0) && (this.login != this.formulario.value.login)) {
          this.messageService.add({ severity: 'info', summary: 'Erro', detail: 'O login ' + this.formulario.value.login + ' já esta em uso na plataforma! Não foi possível gravar seus dados.' });
        }
      })
    }
  }
  onLoadLogin(key) {
    return new Promise(resolve => {
      this.genProvider.onGetfilter('login', 'login', key).subscribe(data => {
        let login = JSON.parse(JSON.stringify(data));
        resolve(login)
      },
        err => console.log(err)
      );
    });
  }
  onSubmit(objeto, data) {
    this.genProvider.onSaveIncrementZero(objeto, data, "usuario", 6).then(t => {
      // this.genProvider.onSaveDocKey(objeto, data.key_login, data).then(t => {
      let i = 0;
      this.permissao.forEach(dados => {
        i++
        //  console.log (i,dados)
        this.genProvider.onSave('login_permissao', dados)
          .then(t => {
          })
          .catch(e => {
            this.messageService.add({ severity: 'info', summary: 'Erro', detail: 'Não foi possível gravar seus dados.' });
          });
      });
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
      this.onMostraFrm();
      this.formulario.reset();

    })
      .catch(e => {
        this.messageService.add({ severity: 'info', summary: 'Erro', detail: 'Não foi possível gravar seus dados.' });
        console.log(e);
      });
  }
  onBloqueio() {
    let check = !this.permissao[0].per_bloqueio
    for (let index = 0; index < this.permissao.length; index++) {
      this.permissao[index].per_bloqueio = check;
    }
  }
  onInserir() {
    let check = !this.permissao[0].per_inserir
    for (let index = 0; index < this.permissao.length; index++) {
      this.permissao[index].per_inserir = check
    }
  }
  onExcluir() {
    let check = !this.permissao[0].per_excluir
    for (let index = 0; index < this.permissao.length; index++) {
      this.permissao[index].per_excluir = check
    }
  }
  onAlterar() {
    let check = !this.permissao[0].per_alterar
    for (let index = 0; index < this.permissao.length; index++) {
      this.permissao[index].per_alterar = check
    }
  }
  onVisualizar() {
    let check = !this.permissao[0].per_visualizar
    for (let index = 0; index < this.permissao.length; index++) {
      this.permissao[index].per_visualizar = check
    }
  }
  onImprimir() {
    let check = !this.permissao[0].per_imprimir
    for (let index = 0; index < this.permissao.length; index++) {
      this.permissao[index].per_imprimir = check
    }
  }
  onGrupo(key) {
    let res = this.grupo.filter(obj => {
      return obj.key_grupo == key
    });
    let nivel = '0'
    if (res[0].nome == "Administrador") {
      nivel = '99'
    }
    this.formulario.patchValue({
      nome_grupo: res[0].nome,
      nivel: nivel
    });
    this.onLoadPermissaoGrupo(key);
  }
  onLoadGrupo() {
    this.unSub.push(this.genProvider.onGetFilterOrder('grupo', 'nome', 'status', 'Ativo').subscribe(data => {
      let grupo = JSON.parse(JSON.stringify(data));
      this.grupo = [];
      grupo.forEach(e => {
        if (this.parametro.nivel == "99") {
          this.grupo.push(e)
        } else if ((this.parametro.nivel != "99") && (e.nivel != "99")) {
          this.grupo.push(e)
        }
      });
    },
      err => console.log(err)
    ))
  }
  onLoadPermissaoGrupo(key) {
    this.unSub.push(this.genProvider
      .onGetFilterOrder('grupo_permissao', 'ordem', 'key_grupo', key)
      .subscribe(data => {
        let res = JSON.parse(JSON.stringify(data))
        if (res.length > 0) {
          res.forEach(e => {
            let reg = this.permissao.filter(obj => {
              return obj.nome == e.nome;
            });
            const index = this.permissao.indexOf(reg[0]);
            if (index >= 0) {
              this.permissao[index].nome = e.nome,
                this.permissao[index].ordem = e.ordem,
                this.permissao[index].per_bloqueio = e.per_bloqueio,
                this.permissao[index].per_inserir = e.per_inserir,
                this.permissao[index].per_alterar = e.per_alterar,
                this.permissao[index].per_excluir = e.per_excluir,
                this.permissao[index].per_imprimir = e.per_imprimir,
                this.permissao[index].per_visualizar = e.per_visualizar

            } else {
              let permissao = {
                children: e.children,
                key: null,
                key_login: this.formulario.value.key_login,
                ordem: e.ordem,
                nome: e.nome,
                per_bloqueio: e.per_bloqueio,
                per_inserir: e.per_inserir,
                per_alterar: e.per_alterar,
                per_excluir: e.per_excluir,
                per_imprimir: e.per_imprimir,
                per_visualizar: e.per_visualizar
              }
              this.permissao.push(permissao)
            }
          });
          this.permissao.sort((a, b) => {
            return a.ordem < b.ordem ? -1 : a.ordem > b.ordem ? 1 : 0;
          })
          //       console.log(this.permissao)
        }
      }));
  }
  exportPdf() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    const doc = new jsPDF('p', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, "Cargos")
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    //doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
      //      columnStyles: {
      //       0: { halign: 'left', fillColor: [220, 220, 220] }
      //     },
      //      margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumns,
      body: lista,
      // foot: this.listaFoot
    });
    doc.save('lista.pdf');
  }
}


