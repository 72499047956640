import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GenProvider } from '../../providers/gen/gen';
import { AlertService } from '../services/alert.service';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs-compat/Subscription';
import { HttpHeaders } from '@angular/common/http';
import { ServiceProvider } from 'src/providers/service/service';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ValidaCampoProvider } from 'src/providers/valida-campo/valida-campo';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs-compat';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { TimeGridSlicer } from '@fullcalendar/timegrid';
import { SafePipe } from 'src/pipes/safe/safe.pipe';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('dtLista') dtLista: Table;
  @ViewChild('dtListaDados') dtListaDados: Table;

  // chart
  productSales: any[]
  productSalesMulti: any[]
  formatString: any;
  formatNumber: any;

  view: any[] = [700, 370];
  progressBar: any;
  // options
  legendTitle: string = 'Dias';
  legendTitleMulti: string = 'Months';
  legendPosition: string = 'below'; // ['right', 'below']
  legend: boolean = true;

  xAxis: boolean = true;
  yAxis: boolean = true;

  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;

  maxXAxisTickLength: number = 30;
  maxYAxisTickLength: number = 30;
  trimXAxisTicks: boolean = false;
  trimYAxisTicks: boolean = false;
  rotateXAxisTicks: boolean = false;

  xAxisTicks: any[] = ['Genre 1', 'Genre 2', 'Genre 3', 'Genre 4', 'Genre 5', 'Genre 6', 'Genre 7']
  yAxisTicks: any[] = [100, 500, 1000, 1500, 2000, 2500, 3000]

  animations: boolean = true; // animations on load

  showGridLines: boolean = true; // grid lines

  showDataLabel: boolean = true; // numbers on bars



  schemeType: string = 'ordinal'; // 'ordinal' or 'linear'

  activeEntries: any[] = ['book']
  barPadding: number = 50;
  tooltipDisabled: boolean = false;

  yScaleMax: number = 5000;

  roundEdges: boolean = false;



  single: any[];

  cardColor: string = '#232837';
  // options
  showLabels: boolean = true;
  isDoughnut: boolean = false;

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA', '#007BFF', '#28A745', '#17A2B8', '#FFC107', '#343A40', '#DC3545']
  };
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  yAxisLabel: string = 'Dias';
  xAxisLabel: string = 'Valor';

  key_lotacao: any;
  key_agente: any;
  key_item: any;
  key_subitem: any;
  filaAgente: any;
  path: any;
  parametros: any;
  mostraUsuario: any;
  key: any;
  chatFila: any;
  loading: boolean;
  chatAtendimento: any;
  fila: number = 0;
  percFila: number = 0;
  percAtendimento: number = 0;
  percEspera: number = 0;
  percPendente: number = 0;
  conversas: any;
  atendimento: any = 0;
  espera: any = 0;
  pendente: any = 0;
  chat_msg: any[];
  mostraMgs: boolean;
  chat: any;
  msgResp: any;
  msgPendente: any;
  ponto: any = [];
  mostraMgsFila: boolean;
  descricao: any;
  novaMsg: any = 0;
  percNovaMsg: number = 0;
  detalhe: any;
  dados: any;
  midia: any =[];
  mostraMidia: boolean;
  agente: any[];
  distribuida: number = 0;
  percDistribuida: number = 0;
  emAtendimento: any;
  data: any =[];
  protocolo: any;
  dataChart: any;
  isStart: any;
  unSub: Subscription[] = [];
  unMsg: Subscription;
  days: any;
  encerrado: any;
  denuncias: any;
  inicio: any;
  final: any;
  mostraMgsAvaliada: boolean;
  situacao: any;
  origem: any;
  denunciaProtocolo: any;
  protocolo2: any;
  idTicket: any;
  dtInicio: any;
  dtFinal: any;
  tickets: any;
  lotacao: any = []
  puc: any;
  ticket_body: any;
  nome_responsavel: any;
  mostraTicket: boolean;
  sla: any;
  subItemCatalogo: any = [];
  itemCatalogo: any = [];
  tipoTicket: any;
  catalogo: any;
  formulario_consulta_ticket: FormGroup;
  formulario_pessoa: FormGroup;
  cols_ticket: any = [];
  usuarios: any;
  listaLotacao: any = [];
  listaCanal: any = [];
  listaAgente: any = [];
  listaPrimeiroNivel: any = [];
  modal_agente: any;
  modal_tools: any;
  dias: any = [];
  qtd_dias: any;
  temp: any = [];
  key_catalogo: string;
  unSub$ = new Subject();
  catalogoAll: any;
  titulo: string;
  listaItem: any = [];
  listaDia: any = [];
  listaStatus_ticket: any = [];
  myBtnFiltro: boolean;
  tempAll: any;
  key_usuario: any;
  nivel: any;
  key_lotacao_principal: any;
  key_pessoa: any;
  pessoa_lotacao: any = [];
  key_minhas_lotacao: any;
  nome_lotacao: any;
  modal_view: any;
  exportColumns: any;
  pessoa: any = [];
  ticket: any = [];
  listaSubitem: any = [];
  subItem: any = []; 
  atendimentos: any;
  listaAtendimento: any;
  atendimentoTotal: any = [];
  atendimentos_telesaude: any;
  listaAtendimento_telesaude: any;
  atendimento_telesaudeTotal: any = [];
  leitos_hospitalares: any;
  listaLeitos_hospitalares: any;
  leitos_hospitalaresTotal: any = [];
  financeiro: any;
  listaFinanceiro: any;
  financeiroTotal: any = [];
  obras: any = [];
  listaObras: any = [];
  obrasTotal: any = [];
  folha: any = [];
  listaFolha: any = [];
  folhaTotal: any = [];
  colsDados: any = [];
  filtros: any = [];
  header: any = [];
  tabela: any;
  listaDados: any = [];
  mostraDados: boolean;
  exportColumnsDados: any;
  showControleAta: boolean;
  frame: any;
  showRelatorio: boolean;
  showSeinfra: boolean;
  showSedur: boolean;
  constructor(
    private genProvider: GenProvider,
    private router: Router,
    private messageService: MessageService,
    private alertService: AlertService,
    private validaCampoProvider: ValidaCampoProvider,
    private safePipe : SafePipe
  ) {
    this.frame="https://hyperchannel.com.br"
      this.midia = {
      type: '',
      file: '',
      lat: 0,
      lng: 0
    }
    this.nome_lotacao = '';
    this.nivel = this.genProvider.parametros.nivel;
    this.key_lotacao = this.genProvider.parametros.key_lotacao;
    this.key_lotacao_principal = this.genProvider.parametros.key_lotacao;
    this.key_pessoa = this.genProvider.parametros.key_pessoa;
    this.progressBar = 'determinate'
    this.myBtnFiltro = false;
    this.filaAgente = this.genProvider.parametros.filaAgente
    this.path = this.genProvider.parametros.path;
    this.parametros = this.genProvider.parametros;
    this.mostraUsuario = this.genProvider.parametros.mostraUsuario
    this.atendimentoTotal.btnClass = 'p-button-help'

  }
  onClose() {
    //this.router.navigate(['']);
  }
  onClose2() {
    this.router.navigate(['']);
  }
  ngOnDestroy() {
    console.log("destroying child...")
    this.unSub.forEach(s => {
      s.unsubscribe();
    });
    if (this.unMsg != undefined) {
      this.unMsg.unsubscribe();
    }
    this.unSub$.next();
    this.unSub$.complete();
  }
  onCombos() {
    this.dias = [
      { nome: '1 dia', qtd: 1 },
      { nome: '3 dias', qtd: 3 },
      { nome: '5 dias', qtd: 5 },
      { nome: 'Uma semana', qtd: 7 },
      { nome: 'Duas semanas', qtd: 14 },
      { nome: 'Três semanas', qtd: 21 },
      { nome: 'Um mês', qtd: 30 },
    ]
  }
  ngOnInit() {
    this.onCombos();
    this.onLoad();
  }
  onLoad() {
    this.onLoadAtendimento();
    this.onLoadAtendimento_telesaude();
    this.onLoadLeitos();
    this.onLoadFinanceiro();
    this.onLoadObras();
    this.onLoadFolha();


  }
  onLoadAtendimento_telesaude() {
    this.genProvider.onGetfilter('dados/' + this.path + '/tabela', "nome", "atendimentos_telesaude").pipe(take(1)).subscribe(res => {
      let tabela = JSON.parse(JSON.stringify(res));
      this.genProvider.onGetAll('dados/' + this.path + '/atendimentos_telesaude', "time").pipe(take(1)).subscribe(data => {
        this.atendimentos_telesaude = JSON.parse(JSON.stringify(data));
        this.listaAtendimento_telesaude = this.atendimentos_telesaude.map(col => ({ name: col.data, value: col.qtd }));
        this.atendimento_telesaudeTotal = {
          label: tabela[0].descricao,
          logo: tabela[0].midia,
          btnClass: tabela[0].btnClass,
          teleconsulta: this.onSum(this.atendimentos_telesaude, 'teleconsulta'),
          telediagnostico: this.onSum(this.atendimentos_telesaude, 'telediagnóstico'),
          telemedicina: this.onSum(this.atendimentos_telesaude, 'telemedicina'),
        }
      });
    })
  }
  onLoadAtendimento() {
    this.genProvider.onGetfilter('dados/' + this.path + '/tabela', "nome", "atendimentos").pipe(take(1)).subscribe(res => {
      let tabela = JSON.parse(JSON.stringify(res));
      this.genProvider.onGetAll('dados/' + this.path + '/atendimentos', "time").pipe(take(1)).subscribe(data => {
        this.atendimentos = JSON.parse(JSON.stringify(data));
        this.listaAtendimento = this.atendimentos.map(col => ({ name: col.data, value: col.qtd }));
        this.atendimentoTotal = {
          label: tabela[0].descricao,
          logo: tabela[0].midia,
          btnClass: tabela[0].btnClass,
          atendimento: this.onSum(this.atendimentos, 'qtd')
        };
      })
    })
  }
  onLoadLeitos() {
    this.genProvider.onGetfilter('dados/' + this.path + '/tabela', "nome", "leitos_hospitalares").pipe(take(1)).subscribe(res => {
      let tabela = JSON.parse(JSON.stringify(res));
      this.genProvider.onGetAll('dados/' + this.path + '/leitos_hospitalares', "time").pipe(take(1)).subscribe(data => {
        this.leitos_hospitalares = JSON.parse(JSON.stringify(data));
        this.leitos_hospitalaresTotal = {
          label: tabela[0].descricao,
          logo: tabela[0].midia,
          btnClass: tabela[0].btnClass,
          clinicos: this.onSum(this.leitos_hospitalares, 'clinicos'),
          uti: this.onSum(this.leitos_hospitalares, 'uti')
        };
      })
    })
  }
  onLoadFinanceiro() {
    this.genProvider.onGetfilter('dados/' + this.path + '/tabela', "nome", "financeiro").pipe(take(1)).subscribe(res => {
      let tabela = JSON.parse(JSON.stringify(res));
      this.genProvider.onGetAll('dados/' + this.path + '/financeiro', "time").pipe(take(1)).subscribe(data => {
        this.financeiro = JSON.parse(JSON.stringify(data));
        this.listaFinanceiro = this.financeiro.map(col => ({ name: col.data, value: col.qtd }));
        this.financeiroTotal = {
          label: tabela[0].descricao,
          logo: tabela[0].midia,
          btnClass: tabela[0].btnClass,
          lista: [
            { name: 'APagar', value: this.onSum(this.financeiro, 'a pagar') },
            // {name:'Empenho', value: this.onSum(this.financeiro, 'notas de empenho')},
            { name: 'Pago', value: this.onSum(this.financeiro, 'pago') },
            //   {name:'Total', value: this.onSum(this.financeiro, 'total')}
          ]
        }
      });
    })
  }
  onLoadObras() {
    this.genProvider.onGetfilter('dados/' + this.path + '/tabela', "nome", "obras").pipe(take(1)).subscribe(res => {
      let tabela = JSON.parse(JSON.stringify(res));
      let fields = [];
      for (let index = 2; index < tabela[0].fields.length; index++) {
        fields.push(tabela[0].fields[index]);
      }
      this.genProvider.onGetAll('dados/' + this.path + '/obras', "time").pipe(take(1)).subscribe(data => {
        let dados = JSON.parse(JSON.stringify(data));
        let chart = dados.map(col => ({ name: col.data, value: col['valor executado'] }));
        this.obrasTotal = {
          label: tabela[0].descricao,
          logo: tabela[0].midia,
          btnClass: tabela[0].btnClass,
          lista: [
            { name: 'Executado', value: this.onSum(dados, 'valor executado') },
            { name: 'Previsto', value: this.onSum(dados, 'valor previsto') },
          ],
          fields:fields,
          data: dados,
          chart: chart
        }
      });
    })
  }
  onLoadFolha() {
    this.genProvider.onGetfilter('dados/' + this.path + '/tabela', "nome", "folha_pagamento").pipe(take(1)).subscribe(res => {
      let tabela = JSON.parse(JSON.stringify(res));
      this.genProvider.onGetAll('dados/' + this.path + '/folha_pagamento', "time").pipe(take(1)).subscribe(data => {
        this.folha = JSON.parse(JSON.stringify(data));
        this.listaFolha = this.folha.map(col => ({ name: col.data, value: col['valor folha'] }));
        this.folhaTotal = {
          label: tabela[0].descricao,
          logo: tabela[0].midia,
          btnClass: tabela[0].btnClass,
          lista: [
            { name: 'Valor', value: this.onSum(this.folha, 'valor folha') }
          ]
        }
      });
    })
  }
  onSum(data, campo) {
    let result: number = 0;
    data.forEach(e => {
      result = result + parseFloat(e[campo])
    });
    return result
  }

  onSumarizar(data, campo, valor) {
    data.sort(function (a, b) {
      if (a[campo] > b[campo]) {
        return 1;
      }
      if (a[campo] < b[campo]) {
        return -1;
      }
      return 0;
    });
    data.array.forEach(e => {
      e[campo]
    });

  }
  onSelect(dados, filtro?: any): void {
    /*
    let data: any = [];
    if (!dados.name) {
      data = {
        name: dados
      }
    } else {
      data = {
        name: dados.name
      }
    }

    if (filtro == 'obras') {
      this.obras= this.obras.filter(obj => {
        return obj.nome_agente == data.name
      })
    }
    */

  }
  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }
  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  onView(data) {
    this.listaDados = data
    this.onComboDados(data.fields)
  }
  onComboDados(header:any ) {
    this.colsDados = header.map(col => ({ header: col.field.toUpperCase(), field: col.field }));
    this.filtros = header.map(col => (
      col.field
    ));
    this.mostraDados = true
      this.exportColumnsDados = this.colsDados.map(col => ({ title: col.header, dataKey: col.field }));
      this.mostraDados = true;
  }
  exportPdfDados() {
    let lista: any;
    if (this.dtListaDados.filteredValue) {
      lista = this.dtListaDados.filteredValue
    } else {
      lista = this.listaDados
    }
    const doc = new jsPDF('l', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, this.tabela)
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    //doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
      //      columnStyles: {
      //       0: { halign: 'left', fillColor: [220, 220, 220] }
      //     },
      //      margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumnsDados,
      body: lista,
      // foot: this.listaFoot
    });
    doc.save('lista_dados_' + this.tabela + '.pdf');
  }
  onShowBi(value){
    if (value=='seinfra'){
   //   this.router.navigate(['dashseinfra'],{ queryParams: { returnRoute: true}});
      this.showSeinfra = true;
    } else if (value=='sedur'){
     // this.router.navigate(['dashsedur']);
      this.showSedur = true;
    } 
        
  }
  url(){
    return this.safePipe.transform(this.frame,'resourceUrl');
  }
}
/*
https://webhook.site/
*/